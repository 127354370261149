.textNone {
    text-decoration: none;
}

.searchBar {
    padding: 15px;
}

.searchNav {
    background-color: var(--primary-color);
}

.custom-form-control {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
}

.custom-form-control:focus {
    border-color: var(--secondary-color) !important;
    /* Highlight color */
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-color-rgb), 0.5) !important;
    /* Highlight shadow */
}

.searchBox {
    color: var(--text-color) !important;
    background-color: var(--background-color);
    position: absolute;
    border: 2px solid var(--text-color) !important;
    border-radius: 3px;
    color: black;
    text-decoration: none;
    padding-left: 7px;
    padding-top: 2px;
}

.searchBox:hover {
    background-color: var(--secondary-color);
}